import React from "react";
import Home from "./Home/Home";
import Service from "./Pages/Service";
import AppliancesDetails from "./Component/AppliancesDetails";
import MobileHeader from "./Mobile/MobileHeader";
import MobileLogin from "./Mobile/MobileLogin";
import MobileServiceDetails from "./Mobile/MobileServiceDetails";
import MobileCart from "./Mobile/MobileCart";
import MobileError from "./Mobile/MobileError";
import Payment from "./Mobile/Payment";
import OrderSuccess from "./Mobile/OrderSuccess";
import MobileApplianceDetails from "./Mobile/MobileApplianceDetails";
import MobileProductDetalis from "./Mobile/MobileProductDetalis";
import Address from "./Mobile/Address";
import MobileProductCart from "./Mobile/MobileProductCart";

import { isMobile } from "react-device-detect";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CheckOutProject from "./CheckOut/CheckOutProject";
import ServiceVew from "./Component/ServiceVew";
import Login from "./Auth/Login";
import Signup from "./Auth/Signup";

import CartServiceTwo from "./Cart/CartServiceTwo";
import AppliancesItemDetailPage from "./Component/AppliancesItemDetailPage";
import UserLocation from "./Mobile/Userlocation";
import About from "./Component/About";
import ContactUs from "./Component/ContactUs";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Thankyou } from "./Component/Thankyou";
import ProductVew from "./Component/ProductVew";
import MyState from "./context/MyState";
import Dashboard from "./user/Dashboard";
import DashboardProfile from "./Component/DashboardProfile";
import Invoice from "./Pages/Invoice";

function App() {
  return (
    <>
      <MyState>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/location" element={<UserLocation />} />
            <Route path="/mobile-login" element={<MobileLogin />} />
            <Route path="/services/:id" element={<MobileServiceDetails />} />
            <Route path="/mobile-cart" element={<MobileCart />} />
            <Route path="/address" element={<Address />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/order-success" element={<OrderSuccess />} />
            <Route path="/mobile-error" element={<MobileError />} />
            <Route
              path="/mobile-all-service"
              element={<MobileServiceDetails />}
            />
            <Route path="/appliance/:id" element={<MobileApplianceDetails />} />
            <Route
              path="/mobile-product-detail/:product_url"
              element={<MobileProductDetalis />}
            />
            <Route path="/mobile-product-cart" element={<MobileProductCart />} />
            <Route path="/service/:service_id" element={<Service />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/dashboard-profile" element={<DashboardProfile />} />
            <Route path="/check-out-product" element={<CheckOutProject />} />
            <Route path="/appliances/:id" element={<AppliancesDetails />} />
            <Route path="/appliances/:id/:product_url" element={<AppliancesItemDetailPage />}
            />
            <Route path="/product/:product_url" element={<ServiceVew />} />  // it is for product details
            <Route path="/productService/:service_url" element={<ProductVew />} />  // it is for Service details
            <Route path="/login" element={<Login />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/registration" element={<Signup />} />
            <Route path="/thank-you" element={<Thankyou />} />
          </Routes>
        </Router>
      </MyState>
    </>
  );
}

export default App;
