import axios from "axios";
import BassURl from "../Api/Api";
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Carousel from "react-elastic-carousel";

function Testimonial() {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 4 },
    { autoplay: true },
  ];

  const addItem = () => {
    const nextItem = Math.max(1, testimonial.length + 1);
    setTestimonial([...testimonial, nextItem]);
  };

  const removeItem = () => {
    const endRange = Math.max(0, testimonial.length - 1);
    setTestimonial(testimonial.slice(0, endRange));
  };
  // api call
  const [testimonial, setTestimonial] = useState([]);
  useEffect(() => {
    axios.get(`${BassURl}api/all-testimonial`).then((response) => {
      setTestimonial(response.data.data);
    });
  }, []);

  const options = {
    margin: 40,
    responsiveClass: true,
    dots: true,
    nav: true,

    navText: [
      "<img  src='/assets/img/left-arrow.webp' class='nav-buttons  owl-prev'/>",
      " <img  src='/assets/img/right-arrow.webp' class='nav-buttons owl-next'/>",
    ],
    loop: true,
    autoplay: true,
    // autoWidth:true,

    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <>
      <section className="light-bg">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="sec-heading center">
                <h2>Good Reviews By Clients</h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="item-slide space testimonial-test">
                <Carousel breakPoints={breakPoints}>
                  {testimonial?.map((data, index) => {
                    console.log(data)
                    return (
                      <>
                        <div className="card-test">
                          <div className="layer" />
                          <div className="content-test">
                            <p className="test-para">
                            
                              {data?.message.substring(0, 250) + "..."}
                            </p>
                            <div className="image">
                              <img
                                src={BassURl + data?.image}
                                alt="avatar"
                              />
                            </div>
                            <div className="details">
                              <h2>{data?.name}</h2> 
                              <span>{data?.designation}</span>
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Testimonial;
