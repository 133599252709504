import React from 'react';
import './FooterMenu.css'
import { motion } from 'framer-motion'
import { Link, NavLink } from 'react-router-dom';

function FooterMenu() {
    return (
        <>
            <motion.footer initial={{ opacity: 0, y: 400 }}
                animate={{ opacity: 2, y: 0 }}
                exit={{ opacity: 0, y: 400 }} className="footer-wrap">
                <ul className="footer">
                    <li className="footer-item active">
                        <Link to="/" className="footer-link">
                            <i className="fa fa-home" aria-hidden="true" />

                            <span>Home</span>
                        </Link>
                    </li>
                    <li activeClassName='active' className="footer-item">
                        <NavLink to="tel:+919696361300" className="footer-link">
                           <i className="fa fa-phone" aria-hidden="true" />
                            <span>Call</span>
                        </NavLink>
                    </li>
                    <li className="footer-item">
                        <NavLink to="https://wa.me/+919696361300" className="footer-link">
                            <i className="fab fa-whatsapp" aria-hidden="true" />
                            <span>Whatsapp</span>
                        </NavLink>
                    </li>
                    <li className="footer-item">
                        <Link to="/mobile-error" className="footer-link">
                           <i className="fa fa-user" aria-hidden="true" />
                            <span>Profile</span>
                        </Link>
                    </li>
                    {/* <li className="footer-item">
                        <Link to="/contact-us" className="footer-link">
                            <i className="fa fa-shopping-bag" aria-hidden="true" />

                            <span>Contact</span>
                        </Link>
                    </li> */}
                </ul>
            </motion.footer>





        </>
    )
}

export default FooterMenu