import React from "react";
import BassURl from "../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, addToCartAction } from "../Redux/Action/CartAction";
import { colors } from "@material-ui/core";
import parse from "html-react-parser";
import { PopupForm } from "../Model/PopupForm";
import { NavLink } from "react-router-dom";
import ProductAddButton from "../Component/ProductAddButton";

function NewTopProduct({ itemsPro, onAddToCartClicked }) {
  const dispatch = useDispatch();
  function addtocart() {
    dispatch(addToCartAction(itemsPro, 1));
  }
  const formatNumberWithCommas = (number) => {
    return new Intl.NumberFormat("en-US").format(number);
  };

  return (
    <>
      {/* <div className="property-listing property-2 productHeight items">
        <div className="listing-img-wrapper">
          <div className="list-img-slide">
            <div className="click">
              <div>
                <NavLink to={`/product/${itemsPro?.product_url}`}>
                  <img loading="lazy"
                    src={BassURl + itemsPro?.product_image}
                    className="img-fluid imgProduct mx-auto"
                    alt=""
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="listing-detail-wrapper">
          <div className="listing-short-detail-wrap">
            <div className="_card_list_flex">
              <div className="_card_flex_01">
                <h4 className="listing-name verified">
                  <NavLink
                    to={`/product/${itemsPro?.product_url}`}
                    className="prt-link-detail"
                  >
                    {itemsPro?.product_title}
                  </NavLink>
                </h4>
                <div className="proudctCardDes" style={{ color: "#fff" }}>
                  {parse(itemsPro?.short_description)}
                </div>

                
              </div>
              <br />
            </div>
            <div
              className="footer-first"
              style={{
                display: "flex",
                marginTop: "12px",
                justifyContent:"space-between"
              }}
            >
              <div className="foot-rates">
                <span className="elio_rate good">4.2</span>
                <div className="_rate_stio">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>

              <div className="">
                <h6 className="listing-card-info-price mb-0  price-style">
                  ₹ &nbsp;
                  {itemsPro?.product_price}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="listing-detail-footer">
          <div className="footer-flex">
            
            <PopupForm/>
          </div>
        </div>
      </div> */}
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12">
            <div className="serviceBox2">
              <div className="service-icon">
                <img
                  loading="lazy"
                  src={BassURl + itemsPro?.product_image}
                  className="img-fluid imgProduct mx-auto"
                  alt=""
                />
              </div>
              <h6
                className="title"
                style={{
                  textAlign: "center",
                  color:"white"
                }}
              >
                <NavLink to={`/product/${itemsPro?.product_url}`} style={{
                  color:"white"
                }} >
                  {itemsPro?.product_title}
                </NavLink>
              </h6>
              <div
                className="footer-first"
                style={{
                  display: "flex",
                  marginTop: "12px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding:"10px"
                }}
              >
                <div className="foot-rates">
                  <span className="elio_rate good">4.2</span>
                  <div className="_rate_stio">
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                    <i className="fa fa-star" />
                  </div>
                </div>

                <div className="">
                  <h6 className=" mb-0  " style={{
                    fontWeight:"600"
                  }}>
                    ₹ &nbsp;
                    {itemsPro?.product_price}
                  </h6>
                </div>
              </div>
              <ProductAddButton data={itemsPro} />
            </div>
          </div>
          {/* <ProductAddButton data={itemsPro} /> */}
        </div>
      </div>

      {/* <div className="card">
        <div className="card-top">
          <NavLink to={`/product/${itemsPro?.product_url}`}>
            <img
              loading="lazy"
              src={BassURl + itemsPro?.product_image}
              className="img-fluid imgProduct mx-auto"
              alt=""
            />
            <h6
              className=""
              style={{
                textAlign: "center",
              }}
            >
              <NavLink to={`/product/${itemsPro?.product_url}`} className="">
                {itemsPro?.product_title}
              </NavLink>
            </h6>
            <div
              className="footer-first"
              style={{
                display: "flex",
                marginTop: "12px",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div className="foot-rates">
                <span className="elio_rate good">4.2</span>
                <div className="_rate_stio">
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                  <i className="fa fa-star" />
                </div>
              </div>

              <div className="">
                <h6 className=" mb-0  ">
                  ₹ &nbsp;
                  {itemsPro?.product_price}
                </h6>
              </div>
            </div>
          </NavLink>

          <ProductAddButton data={itemsPro} />
        </div>
      </div> */}
    </>
  );
}

export default NewTopProduct;
